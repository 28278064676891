<template>
  <register-form :initial-pasien="pasien" :editing="false" />
</template>

<script>
import RegisterForm from "../../components/RegisterForm.vue";

export default {
  name: "Add",

  components: {
    RegisterForm,
  },

  data() {
    return {
      pasien: {
        birthDate: new Date().toISOString().substr(0, 10),
        samplingDate: new Date().toISOString().substr(0, 10),
        receiveSampleDate: new Date().toISOString().substr(0, 10),
        resultDate: new Date().toISOString().substr(0, 10),
        addresss: "",
        phoneNumber: "",
        location: "Laboratorium EBDC",
        identityNumber: "",
        passportNumber: "",
        nationality: "",
        name: "",
        email: "",
        requestLab: "",
        pcrResult: null,
        antigenResult: null,
        gender: "",
      },
    };
  },
};
</script>
